import React from 'react'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Button,
} from 'components'

import { useSharedState } from 'context/sharedState'

import AnimatedWords from './AnimatedWords'

const Hero = ({
  title,
  wordsToRotate,
  subtitleFirstPart,
  subtitleLastPart,
  ctaExtraProps = {},
}) => {
  const [state, setState] = useSharedState()

  const onCTAPress = () => {
    setState({
      ...state,
      trackEvent: 'main_cta_complete',
    })
    window.dataLayer?.push({
      event: 'main_cta_start',
    })
    window._paq?.push(['trackEvent', 'main_cta_start', 'main_cta_start'])
  }

  /**
   * Controlled title wrapping:
   * The "title" prop can be either a simple string or an array of strings.
   * If it's an array of strings then the wrapping will be done after each array element first (can be multiple words),
   * not at the individual words' level.
   */
  const isTitleSuppliedAsLineParts = Array.isArray(title)
  const titleToShow = isTitleSuppliedAsLineParts
    ? title.map((titlePart) => (
        <span
          key={titlePart}
          style={{
            display: 'inline-block',
          }}
        >
          {titlePart}
        </span>
      ))
    : title

  return (
    <Section
      id="werteberechnung"
      sx={{
        position: 'relative',
        display: 'flex',
        height: ['auto', 'auto', '100vh'],
        minHeight: ['auto', '100vh', 880],
        mt: [-64, null, null, -88],
        // maxHeight: ['auto', 980],
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 2400,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 'calc(50% - 400px)',
            transform: 'translateX(50%)',
            bottom: 'calc(100% - 2200px)',
            width: 2800,
            pointerEvents: 'none',
            userSelect: 'none',
            zIndex: 0,
          }}
        >
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div
              aria-hidden="true"
              style={{ width: '100%', paddingBottom: '57%' }}
            >
              <picture>
                <source
                  type="image/webp"
                  srcSet="/ec1ae69ed7afec3a619fa960f0ff6b87/0bc1d/square-iso.webp 300w,
/ec1ae69ed7afec3a619fa960f0ff6b87/fc91b/square-iso.webp 600w,
/ec1ae69ed7afec3a619fa960f0ff6b87/9000d/square-iso.webp 1200w,
/ec1ae69ed7afec3a619fa960f0ff6b87/60b4e/square-iso.webp 1800w,
/ec1ae69ed7afec3a619fa960f0ff6b87/a324c/square-iso.webp 2397w"
                  sizes="(max-width: 1200px) 100vw, 1200px"
                />
                <source
                  srcSet="/ec1ae69ed7afec3a619fa960f0ff6b87/37d5a/square-iso.png 300w,
/ec1ae69ed7afec3a619fa960f0ff6b87/8c332/square-iso.png 600w,
/ec1ae69ed7afec3a619fa960f0ff6b87/6050d/square-iso.png 1200w,
/ec1ae69ed7afec3a619fa960f0ff6b87/69278/square-iso.png 1800w,
/ec1ae69ed7afec3a619fa960f0ff6b87/349cd/square-iso.png 2397w"
                  sizes="(max-width: 1200px) 100vw, 1200px"
                />
                <img
                  sizes="(max-width: 1200px) 100vw, 1200px"
                  srcSet="/ec1ae69ed7afec3a619fa960f0ff6b87/37d5a/square-iso.png 300w,
/ec1ae69ed7afec3a619fa960f0ff6b87/8c332/square-iso.png 600w,
/ec1ae69ed7afec3a619fa960f0ff6b87/6050d/square-iso.png 1200w,
/ec1ae69ed7afec3a619fa960f0ff6b87/69278/square-iso.png 1800w,
/ec1ae69ed7afec3a619fa960f0ff6b87/349cd/square-iso.png 2397w"
                  src="/ec1ae69ed7afec3a619fa960f0ff6b87/6050d/square-iso.png"
                  alt=""
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center center',
                    opacity: 1,
                    transition: 'opacity 500ms ease 0s',
                  }}
                />
              </picture>
            </div>
          </div>
        </Box>
      </Box>

      <Container>
        <Row>
          <Column
            size={[1, null, 7 / 12, 2 / 3]}
            sx={{ position: 'relative', ml: 'auto' }}
          >
            <Box
              sx={{
                // width: calc(100vw * 2);
                // top: calc(100vw - 240px);
                // transform: none;
                // position: relative;
                // left: calc(-100vw - 120px);

                position: ['relative', 'absolute'],
                top: [
                  0,
                  // 'calc(100vw - 180px)',
                  'calc(100vw - 280px)',
                  'calc(50% + 120px)',
                ],
                left: ['calc(-80vw - 120px)', 'auto'],
                right: ['auto', 'calc(100% + 40px)'],
                width: ['calc(80vw * 2)', 1200],
                transform: ['none', 'translateY(-50%)'],
                pointerEvents: 'none',
                userSelect: 'none',
                zIndex: 0,
              }}
            >
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                <div
                  aria-hidden="true"
                  style={{ width: '100%', paddingBottom: '79%' }}
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="/hero-illustration/0bc1d/hero-iso-compressed.webp 300w,
                          /hero-illustration/fc91b/hero-iso-compressed.webp 600w,
                          /hero-illustration/9000d/hero-iso-compressed.webp 1200w,
                          /hero-illustration/60b4e/hero-iso-compressed.webp 1800w,
                          /hero-illustration/83a9d/hero-iso-compressed.webp 2400w,
                          /hero-illustration/bb3ce/hero-iso-compressed.webp 2730w"
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                    <source
                      srcSet="/hero-illustration/37d5a/hero-iso-compressed.png 300w,
                      /hero-illustration/8c332/hero-iso-compressed.png 600w,
                      /hero-illustration/6050d/hero-iso-compressed.png 1200w,
                      /hero-illustration/69278/hero-iso-compressed.png 1800w,
                      /hero-illustration/1f96e/hero-iso-compressed.png 2400w,
                      /hero-illustration/bd39a/hero-iso-compressed.png 2730w"
                      sizes="(max-width: 1200px) 100vw, 1200px"
                    />
                    <img
                      sizes="(max-width: 1200px) 100vw, 1200px"
                      srcSet="/hero-illustration/37d5a/hero-iso-compressed.png 300w,
                      /hero-illustration/8c332/hero-iso-compressed.png 600w,
                      /hero-illustration/6050d/hero-iso-compressed.png 1200w,
                      /hero-illustration/69278/hero-iso-compressed.png 1800w,
                      /hero-illustration/1f96e/hero-iso-compressed.png 2400w,
                      /hero-illustration/bd39a/hero-iso-compressed.png 2730w"
                      src="/hero-illustration/6050d/hero-iso-compressed.png"
                      alt=""
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center center',
                        opacity: 1,
                        transition: 'opacity 500ms ease 0s',
                      }}
                    />
                  </picture>
                </div>
              </div>
            </Box>
            <Box
              sx={{
                position: 'relative',
                zIndex: 5,
                marginTop: ['-60%', 0, null, null],
              }}
            >
              <Heading.H1
                sx={{
                  mt: [4, 0],
                  mb: 4,
                  textAlign: ['center', 'left'],
                  display: ['flex', 'block'],
                  flexDirection: ['column', 'unset'],
                  whiteSpace: ['initial', 'pre-wrap'],
                }}
              >
                {titleToShow}
              </Heading.H1>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: ['center', 'start'],
                  fontSize: 3,
                  mb: 6,
                }}
              >
                <Box
                  sx={{
                    textAlign: ['center', 'left'],
                    width: ['100%', 'auto'],
                  }}
                >
                  {subtitleFirstPart}
                </Box>
                <AnimatedWords words={wordsToRotate} />
                <Box
                  sx={{
                    textAlign: ['center', 'left'],
                    width: ['100%', 'auto'],
                  }}
                >
                  {subtitleLastPart}
                </Box>
              </Box>

              <Button
                loading=""
                variant="v2Main1"
                id="main_cta_start"
                as="a"
                href="#anmelden"
                onClick={onCTAPress}
                sx={{
                  width: ['100%', 'auto'],
                  maxWidth: 300,
                  mx: ['auto', 0],
                }}
                {...ctaExtraProps}
              >
                Kostenlos anfragen
              </Button>
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default Hero
