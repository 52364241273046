import React from 'react'

import { Section, Container, Flex, Box, Heading, Image } from 'components'

const partner = [
  {
    imageUrl: '/partner/1.png',
    name: 'Commerz Bank',
    width: 172,
    height: 31,
  },
  {
    imageUrl: '/partner/2.png',
    name: 'DSL Bank',
    width: 94,
    height: 40,
  },
  {
    imageUrl: '/partner/3.png',
    name: 'PSD Berlin',
    width: 131,
    height: 40,
  },
  {
    imageUrl: '/partner/4.png',
    name: 'Münchner Hyp',
    width: 70,
    height: 40,
  },
  {
    imageUrl: '/partner/5.png',
    name: 'Sparda Bank',
    width: 172,
    height: 36,
  },
  {
    imageUrl: '/partner/6.png',
    name: 'Vereinigte VR Bank',
    width: 172,
    height: 28,
  },
]

const Partner = ({ backgroundColor = 'colorBlue2' }) => {
  return (
    <Section bg={backgroundColor} spacing="large">
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" color="darkNavy80">
            Starke Partner - Starke Kooperationen
          </Heading.H2>
        </Section.Header>

        <Section.Body>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              py: [6, 0],
              m: [-3],
            }}
          >
            {partner.map(({ imageUrl, name, width, height }, index) => (
              <Box
                key={index}
                p={3}
                sx={{
                  '&>img': {
                    width,
                    height,
                    mx: 'auto',
                  },
                }}
              >
                <img
                  display="block"
                  loading="lazy"
                  width={width}
                  height={height}
                  src={imageUrl}
                  alt={name}
                  role="presentation"
                />
              </Box>
            ))}
          </Flex>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default Partner
