import React from 'react'

import { Section, Container, Row, Column, Box, Heading, Text } from 'components'

import LeadCTAButton from 'components/LeadCTAButton'

const Summary = ({
  title,
  items = [],
  ctaExtraProps = {},
  leadModalType = 'teilverkauf',
  ctaButtonID,
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section bg={backgroundColor} spacing="large">
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center">{title}</Heading.H2>
        </Section.Header>

        <Section.Body>
          <Row justifyContent="center" py={[0, null, 6]}>
            {items.map(
              ({ icon, title, description, iconWidth, iconHeight }, index) => (
                <Column key={icon} size={[1, null, 1 / 3]}>
                  <Box
                    sx={{
                      px: 3,
                      py: [5, 2],
                      textAlign: 'center',
                      maxWidth: [320, null, 'none'],
                      mx: 'auto',
                      '&>img': {
                        ...(iconWidth && { width: iconWidth }),
                        ...(iconHeight && { height: iconHeight }),
                        mb: 2,
                      },
                    }}
                  >
                    <img
                      src={icon}
                      alt={title}
                      loading="lazy"
                      role="presentation"
                      height={iconHeight || '128'}
                      width={iconWidth || '40'}
                    />
                    <Heading.H4 as="h3" mb={2}>
                      {title}
                    </Heading.H4>
                    <Text fontSize={2}>{description}</Text>
                  </Box>
                </Column>
              )
            )}
          </Row>
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }}>
          <LeadCTAButton
            variant="v2Main1"
            leadModalType={leadModalType}
            ctaButtonID={ctaButtonID}
            sx={{
              width: ['100%', 'auto'],
              maxWidth: 300,
              mx: ['auto', 0],
            }}
            {...ctaExtraProps}
          >
            Unverbindlich anfragen!
          </LeadCTAButton>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Summary
