import React, { useEffect, useState } from 'react'

import { Box } from 'components'
import { backgroundColor } from 'styled-system'

const VerticalCarousel = ({
  words,
  leadingText,
  backgroundColor = 'white',
  textColor = 'darkNavy100',
  borderColor = 'colorBlue10',
  width = 275,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(words.length / 2)

  // Usd to determine the height/spacing of each item
  const itemHeight = 40

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  // const visibleStyleThreshold = shuffleThreshold / 2
  const visibleStyleThreshold = shuffleThreshold / 3

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight
      } else {
        return -(words.length + activeIndex - itemIndex) * itemHeight
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (words.length - (activeIndex - itemIndex)) * itemHeight
      }
      return -(activeIndex - itemIndex) * itemHeight
    }
  }

  const changeSlide = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === 'next') {
        if (prevIndex + 1 > words.length - 1) {
          return 0
        }
        return prevIndex + 1
      }

      if (prevIndex - 1 < 0) {
        return words.length - 1
      }

      return prevIndex - 1
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      changeSlide('next')
    }, 2500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor,
        bg: backgroundColor,
        fontWeight: 'bold',
        borderRadius: 'default',
        whiteSpace: 'nowrap',
        color: textColor,
        px: 3,
        mx: [0, 2],
        my: [2, 0],
        height: 40,
        width,
        lineHeight: '38px',
        overflow: 'hidden',
      }}
    >
      {words.map((word, i) => (
        <Box
          key={word}
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            padding: 0,
            margin: 0,
            opacity: 0,
            textAlign: 'center',
            transition: 'transform 500ms ease, opacity 500ms ease',
          }}
          style={{
            transform: `translateY(${determinePlacement(i)}px)`,
            opacity:
              Math.abs(determinePlacement(i)) <= visibleStyleThreshold ? 1 : 0,
          }}
        >
          {word}
        </Box>
      ))}
    </Box>
  )
}

export default VerticalCarousel
