import React, { useState } from 'react'

import { Box, Text, Button } from 'components'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { isBrowser } from 'utils'

import IconAT from 'images/icons/countries/AT.svg'
import IconDE from 'images/icons/countries/DE.svg'

import { useLocation } from 'context/location'

const Flag = ({ countryCode }) => (
  <Box as={countryCode === 'DE' ? IconDE : IconAT} display="block" width={20} />
)

const LocationDropdown = () => {
  const ref = React.useRef()
  const [isOpen, setOpen] = useState(false)
  useOnClickOutside(ref, () => setOpen(false))

  const { countryCode, setCountryCode } = useLocation()

  if (!isBrowser) {
    return <Box width={[null, null, 36]} />
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        as="button"
        variant="buttons.blank"
        sx={{
          display: 'block',
          px: [1, null, null, 2],
          py: 2,
          outline: 'none',
        }}
        onClick={() => setOpen(!isOpen)}
      >
        <Flag countryCode={countryCode} />
      </Box>
      <Box
        ref={ref}
        sx={{
          display: isOpen ? 'block' : 'none',
          position: 'absolute',
          top: '100%',
          right: ['auto', '0%'],
          left: ['50%', 'auto'],
          transform: ['translateX(-50%)', 'none'],
          pt: [3, null, null, 2],
        }}
      >
        <Box
          sx={{
            position: 'relative',
            minWidth: 148,
            py: [1],

            bg: 'white',
            border: '1px solid',
            borderColor: 'darkNavy20',
            borderRadius: '4px',

            '&:after, &:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 0,
              height: 0,
              borderStyle: 'solid',
              right: ['auto', 0],
              left: ['50%', 'auto'],
              transform: ['translate(-50%,-100%)', 'translate(-8px,-100%)'],
            },

            '&:after': {
              top: '1.5px',
              borderColor: 'transparent',
              borderBottomColor: 'white',
              borderWidth: '0 9px 9px 9px',
            },

            '&:before': {
              top: '0px',
              borderColor: 'transparent',
              borderBottomColor: 'darkNavy20',
              borderWidth: '0 9px 9px 9px',
            },
          }}
        >
          <Button
            as="button"
            variant="blank"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              fontSize: 1,
              textAlign: 'left',
              borderRadius: 0,
              py: 1,
              px: 2,
              outline: 'none',

              '&:hover': {
                bg: 'colorBlue5',
              },
            }}
            onClick={() => {
              setCountryCode('AT')
              setOpen(false)
            }}
          >
            <Flag countryCode="AT" />
            <Text ml={2}>Österreich</Text>
          </Button>
          <Button
            as="button"
            variant="blank"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              fontSize: 1,
              textAlign: 'left',
              borderRadius: 0,
              py: 1,
              px: 2,
              outline: 'none',

              '&:hover': {
                bg: 'colorBlue5',
              },
            }}
            onClick={() => {
              setCountryCode('DE')
              setOpen(false)
            }}
          >
            <Flag countryCode="DE" />
            <Text ml={2}>Deutschland</Text>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default LocationDropdown
