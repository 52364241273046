import React from 'react'

import { Section, Container, Box, Flex, Heading, Text } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const Flow = ({
  title,
  titleID = null,
  items = [],
  hideCTA = false,
  ctaElement = null,
  aboveCTAElement = null,
  hideAboveCTAText = false,
  aboveCTAText = 'Ihr Berater erstellt für Sie die passende Finanzierung aus dem Angebot unserer vielen Bankpartner.',
  ctaText = 'Kostenlos anfragen',
  backgroundColor = 'colorBlue2',
  ctaExtraProps = {},
  ctaButtonID,
  leadModalType = 'standard_offer',
  noNumbering = false,
}) => {
  return (
    <Section bg={backgroundColor} spacing="large">
      <Box
        id="so-funktioniert-es"
        sx={{ position: 'relative', top: [-64 - 20, null, null, -88 - 20] }}
      />
      {titleID && (
        <Box
          id={titleID}
          sx={{ position: 'relative', top: [-64 - 20, null, null, -88 - 35] }}
        />
      )}
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" color="darkNavy80">
            {title}
          </Heading.H2>
        </Section.Header>

        <Section.Body>
          <Box
            sx={{
              position: 'relative',
              ':after': {
                content: '""',
                position: 'absolute',
                width: 1,
                bg: 'darkNavy20',
                top: 0,
                bottom: 0,
                left: [0, null, '50%'],
              },
            }}
          >
            {items.map(({ title, description, iconSrc }, index) => {
              const isOdd = (index + 1) % 2 !== 0
              return (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    pr: isOdd ? [0, null, 6] : 0,
                    pl: !isOdd ? [4, null, 6] : [4, null, 0],
                    mb: [3, null, 0],
                    width: [null, null, '50%'],
                    left: [null, null, isOdd ? 0 : '50%'],
                  }}
                >
                  {index < items.length - 1 && (
                    <Box
                      as="svg"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 37 36"
                      sx={{
                        width: 36,
                        height: 36,
                        ml: -17,
                        position: 'absolute',
                        bottom: [-2, null, -3],
                        left: [0, null, isOdd ? '100%' : 0],
                      }}
                    >
                      <Box
                        as="path"
                        sx={{ color: 'colorBlue10' }}
                        fill="currentColor"
                        d="M28.6 14.6l-9 9c-.4.3-.7.4-1.1.4-.4 0-.8-.1-1-.4l-9-9c-.7-.7-.7-1.5 0-2.2.6-.6 1.4-.6 2 0l8 8 8-8c.6-.6 1.4-.6 2 0 .6.7.6 1.5 0 2.2z"
                      />
                    </Box>
                  )}
                  <Flex
                    sx={{
                      flexDirection: ['column', 'column', 'row'],
                      alignItems: [null, null, 'center'],
                      px: [3, 3, 6],
                      py: 3,
                      bg: 'white',
                      borderRadius: 'large',
                      boxShadow: 'sm',
                      border: '1px solid',
                      borderColor: 'colorBlue10',
                    }}
                  >
                    <Box flexShrink={0} order={[null, null, isOdd ? 99 : null]}>
                      {!noNumbering && !iconSrc && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            lineHeight: '24px',
                            color: 'white',
                            bg: 'darkNavy100',
                            borderRadius: 'circle',
                            fontSize: 0,
                            fontWeight: 'bold',
                            mb: [3, 3, 0],
                          }}
                        >
                          <Box sx={{ marginRight: '-1px' }}>{index + 1}</Box>
                        </Box>
                      )}
                      {iconSrc && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: [3, null, 0],
                          }}
                        >
                          <img
                            src={iconSrc}
                            alt={title}
                            width="42"
                            height="42"
                            role="presentation"
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        pr: isOdd ? [0, 0, 4] : 0,
                        pl: !isOdd ? [0, 0, 4] : 0,
                        flexGrow: 1,
                      }}
                    >
                      <Heading.H4 mb={2}>{title}</Heading.H4>
                      <Text fontSize={2}>{description}</Text>
                    </Box>
                  </Flex>
                </Box>
              )
            })}
          </Box>
        </Section.Body>
        <Section.Footer sx={{ textAlign: ['left', 'center'] }}>
          {aboveCTAElement}
          {!hideAboveCTAText && (
            <Text fontSize={0} pt={3} pb={4}>
              {aboveCTAText}
            </Text>
          )}
          {ctaElement ? (
            ctaElement
          ) : !hideCTA ? (
            <LeadCTAButton
              ctaButtonID={ctaButtonID}
              leadModalType={leadModalType}
              variant="primary"
              width={['100%', 'auto']}
              {...ctaExtraProps}
            >
              {ctaText}
            </LeadCTAButton>
          ) : null}
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Flow
