import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'

import Hero from 'screens/Home/components/Hero'
import Benefits from 'screens/Home/components/Benefits'
import Flow from 'screens/Home/components/Flow'
import Summary from 'screens/Home/components/Summary'
import Comparison from 'screens/Home/components/Comparison'
import Faq from 'screens/Home/components/Faq'
import Testimonials from 'screens/Home/components/Testimonials'
import Regulations from 'screens/Home/components/Regulations'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import Partner from 'screens/Home/components/Partner'

import { useLocation, useTranslate } from 'context/location'

// import { PARTNER_FORM_URL } from 'config'

// export const partnerCTAProps = {
//   href: PARTNER_FORM_URL,
//   target: '_blank',
//   rel: 'noopener noreferrer',
//   onClick: () => {
//     window.dataLayer?.push({
//       event: 'partner_CTA_start',
//     })
//     window._paq?.push(['trackEvent', 'partner_CTA_start', 'partner_CTA_start'])
//   },
// }

const Page = (props) => {
  // const translate = useTranslate()
  // const { countryCode } = useLocation()

  // const {
  //   featuredArticles: { articles: featuredArticles },
  // } = useStaticQuery(
  //   graphql`
  //     query {
  //       featuredArticles: contentfulFeaturedArticlesLayout(
  //         node_locale: { eq: "de" }
  //         slug: { eq: "beleihen" }
  //       ) {
  //         articles {
  //           ...Article
  //         }
  //       }
  //     }
  //   `
  // )

  useEffect(() => {
    window.location.replace(
      'https://share.hsforms.com/1fPQdxU3XREiY574J85JPGA3iqqs'
    )
  }, [])

  return (
    <Layout
      seo={{
        title: 'Immobilien sind wertvoll',
        description:
          'Finanzielle Flexibilität und Liquidität für Immobilienbesitzer und deren Wunschprojekte',
      }}
    >
      {/* Soft hyphenation is used to prevent forced breaking
      <Hero
        title="Werden Sie Bambus Partner"
        wordsToRotate={[
          'Liquidität für Projekte',
          'finanzielle Flexibilität',
          'Sonderlösungen',
          'Optionen für neue Wege',
        ]}
        subtitleFirstPart="Verschaffen Sie Ihren Kunden"
        subtitleLastPart=""
        ctaExtraProps={partnerCTAProps}
      />
      <Benefits
        title="Bieten Sie Ihren Kunden Liquidität für jeden Bedarf"
        items={[
          {
            title: 'Projekte erfolgreich starten',
            description:
              'Projekte mit Liquidität durch die Bestandsimmobilie schon jetzt realisieren oder im größeren Umfang angehen',
            icon: '/icons/round-grow.svg',
          },
          {
            title: 'Finanzielle Flexibilität',
            description:
              'Ermöglichen Sie Ihrem Kunden bei Bedarf eine Ratenzahlung',
            icon: '/icons/round-cover.svg',
          },
          {
            title: 'Sonderlösungen',
            description:
              'Manchmal sind Finanzsituationen komplexer. Wir sind darauf spezialisiert Sonderlösungen zu finden',
            icon: '/icons/round-finance.svg',
          },
          {
            title: 'Optionen für neue Wege',
            description:
              'Verschaffen Sie Ihren Kunden die passenden Optionen zur Realisierung der Wunschprojekte',
            icon: '/icons/round-retirement.svg',
          },
        ]}
        ctaExtraProps={partnerCTAProps}
      />
      <Flow
        title="Kundenprojekte erfolgreich umsetzen leicht gemacht"
        items={[
          {
            title: 'Der Kundenwunsch',
            description:
              'Ihr Kunde hat Vermögen, aber ist nicht liquide? Projekte werden dadurch nicht realisiert oder verzögert. Denken Sie an Bambus',
          },
          {
            title: 'Ihre Empfehlung',
            description:
              'Schreiben Sie uns eine Mail, dass Sie Ihren Kunden an uns weiter empfohlen haben. Der Kunde kann direkt über unsere Landingpage oder eine bei Ihnen integrierte Micropage mit uns Kontakt aufnehmen',
          },
          {
            title: 'Unser Service',
            description:
              'Im persönlichen Beratungsgespräch zeigen wir dem Kunden seine individuellen Finanzoptionen auf. Der Kunde erhält nach Eingabe der Kundendaten ein unverbindliches Angebot',
          },
          {
            title: 'Projekte erfolgreich starten',
            description:
              'Bei erfolgreicher Bank-Prüfung hat der Kunde einen Teil seines Immoblienvermögens liquide am Konto und Sie eine Provision',
          },
        ]}
        ctaExtraProps={partnerCTAProps}
      />
      <Testimonials />
      <Comparison
        title="Die bessere Wahl"
        items={[
          {
            title: 'Girobank',
            details: [
              'Begrenzte Auswahl',
              'Eingeschränkte Verwendung',
              'Kein Überblick',
              'Analoge Prozesse',
            ],
          },
          {
            primary: true,
            title: null,
            details: [
              'Eine Anfrage - viele Banken',
              'Kostenfreie Beratung',
              'Freie Mittelverwendung',
              'Einfache Prozesse',
            ],
          },
          {
            title: 'Verkauf',
            details: [
              'Verkaufspreis ungewiss',
              'Langwieriger Prozess',
              'Großer Aufwand',
              'Hohe Gebühren',
            ],
          },
        ]}
        ctaExtraProps={partnerCTAProps}
      />
      <Faq />
      <Summary
        title="Bambus funktioniert"
        items={[
          {
            icon: '/icons/bambus-clarity.svg',
            iconWidth: '77px',
            title: 'Übersichtlich',
            description:
              'Wir sorgen dafür, dass Sie stets den Überblick behalten.',
          },
          {
            icon: '/icons/bambus-transparency.svg',
            iconWidth: '79px',
            title: 'Transparent',
            description:
              'Jeder Schritt ist für Sie nachvollziehbar und verständlich – ohne Abschluss keine Bezahlung.',
          },
          {
            icon: '/icons/bambus-sustainability.svg',
            iconWidth: '82px',
            title: 'Nachhaltig',
            description:
              'Unsere Beratung konzentriert sich auf nachhaltige Finanzierung.',
          },
        ]}
        ctaExtraProps={partnerCTAProps}
      />
      <Regulations country={countryCode} />
      <Blog
        title="Entdecken Sie unsere Finanzierungsratgeber"
        articles={featuredArticles}
      />
      <Map />
      <Partner /> */}
    </Layout>
  )
}
export default Page
