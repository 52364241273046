import React from 'react'

import { Section, Container, Box, Flex, Heading, Text, Grid } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const Benefits = ({
  title,
  subheading,
  items = [],
  hideCTA = false,
  ctaExtraProps = {},
  backgroundColor = 'white',
  ctaButtonID,
}) => {
  return (
    <Box sx={{ position: 'relative', backgroundColor }}>
      {/* An invisible element used only for anchoring purposes. We can't give the anchor ID directly to the Section element as then the fixed header would overlap it */}
      <Box id="vorteile" sx={{ position: 'absolute', top: '-40px' }} />
      <Section spacing="large" id="vorteile">
        <Container>
          <Section.Header>
            <Heading.H2 textAlign="center" color="darkNavy80">
              {title}
            </Heading.H2>
            {subheading && (
              <Text mt={3} fontSize={3} textAlign="center" color="darkNavy80">
                {subheading}
              </Text>
            )}
          </Section.Header>

          <Section.Body>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'colorBlue10',
                borderRadius: 'large',
              }}
            >
              <Grid gap={0} columns={[1, null, 2]}>
                {items.map(({ icon, title, description }, index) => (
                  <Box
                    key={icon}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRight: [
                        null,
                        null,
                        index % 2 === 0 ? '1px solid' : 'none',
                      ],
                      borderTop: [
                        index > 0 ? '1px solid' : 'none',
                        null,
                        index > 1 ? '1px solid' : 'none',
                      ],
                      borderColor: ['colorBlue10', null, 'colorBlue10'],
                    }}
                  >
                    <Flex
                      flexDirection={['column', 'row']}
                      alignItems={[null, 'center']}
                      px={[5, 6]}
                      py={[5, 6]}
                    >
                      <Box
                        flexGrow={0}
                        flexShrink={0}
                        sx={{
                          '&>img': {
                            width: [32, 46],
                            height: [32, 46],
                            mb: [2, 0],
                          },
                        }}
                      >
                        <img
                          src={icon}
                          alt={title}
                          width="32"
                          height="32"
                          role="presentation"
                        />
                      </Box>
                      <Box flexGrow="1" pl={[0, 6]}>
                        <Heading.H4 mb={2}>{title}</Heading.H4>
                        <Text fontSize={2}>{description}</Text>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Grid>
            </Box>
          </Section.Body>
          {!hideCTA && (
            <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}>
              <LeadCTAButton
                ctaButtonID={ctaButtonID}
                variant="v2Main1"
                leadModalType="standard_offer"
                sx={{
                  width: ['100%', 'auto'],
                  maxWidth: 300,
                  mx: ['auto', 0],
                }}
                {...ctaExtraProps}
              >
                Unverbindlich anfragen
              </LeadCTAButton>
            </Section.Footer>
          )}
        </Container>
      </Section>
    </Box>
  )
}

export default Benefits
