import React from 'react'

import { Link } from 'components'

import { useLocation } from 'context/location'

const phoneNumberByCountryCode = (countryCode) => {
  switch (countryCode) {
    case 'DE':
      return '+49 30 837 960 40'
    default:
      return '+43 1 435 0123'
  }
}

const PhoneLink = (props) => {
  const { countryCode } = useLocation()
  const localizedPhoneNumber = phoneNumberByCountryCode(countryCode)

  return (
    <Link href={`tel:${localizedPhoneNumber}`} {...props}>
      {localizedPhoneNumber}
    </Link>
  )
}

const EmailLink = (props) => {
  return (
    <Link href="mailto:beratung@bambus.io" {...props}>
      beratung@bambus.io
    </Link>
  )
}

export { PhoneLink, EmailLink }
